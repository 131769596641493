import React from "react";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { useGetInfo } from "../../hooks/useInfo";
import { useGetEnv } from "../../hooks/useEnv";
import Notifications from "../../features/Notifications";
import NavMenu from "./NavMenu";

function TopNav() {
  const env = useGetEnv();
  const isMobile = !useMediaQuery("(min-width:960px)");
  const isScrollY = useScrollTrigger({
    threshold: 90,
    disableHysteresis: true,
  });
  const { pathname } = useLocation();
  const { data: info, isLoading } = useGetInfo();

  function scrollToAnchor(ev: React.MouseEvent<HTMLAnchorElement>) {
    ev.preventDefault();
    document.getElementById("partners")?.scrollIntoView({
      behavior: "smooth",
    });
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const showCompact = isScrollY || isMobile;
  const logoSrc = showCompact ? "/img/1wa-icon.png" : "/img/1wa-logo.svg";

  if (isLoading) return null;

  return (
    <nav
      className={`nav${
        showCompact
          ? "-compact drop-down"
          : ["/", "/home"].includes(pathname)
          ? "-landing"
          : ""
      }`}
    >
      <div className={`nav${showCompact ? "-compact" : ""}-left`}>
        <Link
          to="/"
          className={showCompact ? "link-inert" : ""}
          onMouseDown={scrollToTop}
        >
          <img src={logoSrc} alt="1WA Logo" />
        </Link>
      </div>

      {info ? (
        <div className={`nav${showCompact ? "-compact" : ""}-right`}>
          <Link to="/" className="link-nav desktop" onMouseDown={scrollToTop}>
            {info.home}
          </Link>
          <Link to="/products" className="link-nav desktop">
            {info.products}
          </Link>
          <Link to="/partners" className="link-nav desktop">
            {info.partners}
          </Link>
          <Link to="https://support.1wa.org" className="link-nav desktop">
            {info.support}
          </Link>

          <Link
            to="https://lms.1wa.org"
            // className={`desktop ${
            //   showCompact
            //     ? "link-q-inverse"
            //     : ["/"].includes(pathname)
            //     ? "link-q"
            //     : "link-q-inverse"
            // }`}
            className="link-nav desktop"
          >
            QAcademy
            {/* <span id="new">NEW</span> */}
          </Link>

          <a
            href={env?.oasys_login_url}
            target="_blank"
            rel="noreferrer"
            className="link-login desktop"
          >
            {info.login}®
          </a>
          <Notifications />
          <NavMenu />
        </div>
      ) : null}
    </nav>
  );
}

export default TopNav;
