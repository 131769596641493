import { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { useGetInfo } from "../../hooks/useInfo";

import { useGetMenuStories } from "../../hooks/useStory";
import LocaleSelector from "../Locale";

function NavMenu() {
  const { data: info } = useGetInfo();
  const menuItems = useGetMenuStories();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleClick(ev: React.MouseEvent<HTMLElement>) {
    setAnchorEl(ev.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (menuItems.isLoading) return null;

  return (
    <>
      <span
        aria-controls="nav-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="link-hamburger"
      >
        <i className="fa-sharp fa-solid fa-bars"></i>
      </span>

      <Menu
        id="nav-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClick={handleClose}
      >
        <div className="mobile">
          <LocaleSelector />
        </div>
        <div className="nudge-sm"></div>
        <Link to="/" className="link-dialog" id="MENU_HOME_MOBILE">
          Home
        </Link>
        <Link to="/products" className="link-dialog" id="MENU_HOME_MOBILE">
          Products
        </Link>
        <Link to="/partners" className="link-dialog" id="MENU_HOME_MOBILE">
          Partners
        </Link>
        <Link
          to="https://lms.1wa.org"
          className="link-dialog"
          id="MENU_HOME_MOBILE"
        >
          QAcademy
        </Link>
        <Link to="https://support.1wa.org" className="link-dialog">
          {info.support}
        </Link>
        <div className="divider"></div>
        <Link to="/qacademy" className="link-dialog" id="MENU_HOME_MOBILE">
          Introducing QAcademy
        </Link>

        {menuItems.data
          ?.slice()
          .sort((a, b) =>
            a.content_versions.menuName.localeCompare(
              b.content_versions.menuName
            )
          )
          .map((item) => (
            <div key={item.id}>
              <Link to={`/${item.route}`} className="link-dialog">
                {item.content_versions.menuName}
              </Link>
            </div>
          ))}
      </Menu>
    </>
  );
}

export default NavMenu;
